<template>
	<div>
		<ManageFilterHeaderInfo
			:title="localTitle"
			:description="localDescription"
			class="mb-4"
		/>
		<ManageFilterSearchAndFilter
			:search-value="searchValue"
			:filter-value="filterValue"
			:filter-options="filterOptions"
			@update:search-value="handleEmit('update:search-value', $event)"
			@update:filter-value="handleEmit('update:filter-value', $event)"
			@onSearch="handleSearch"
		/>
		<BaseTable
			:is-loading="isLoading"
			:fields="fields"
			:items="list"
			:pagination="tablePagination"
			striped
			class="table-custom"
			@onPaginationClick="handlePageChange"
		>
			<template #no-items-view>
				<div class="empty-table-element">
					<p class="subtitle">
						{{ $t("global.searchNotFound", { field: "attribute key" }) }}
					</p>
				</div>
			</template>
			<template #name="{ item = '' }">
				{{ item }}
			</template>
			<template #data="{ item = {} }">
				<div class="container-switch text-right mr-3">
					<CSwitch
						:checked="item.status"
						color="success"
						variant="3d"
						size="sm"
						class="switch-custom toggle-status"
						@update:checked="handleSwitch({
							id: item.id,
							value: $event,
						})"
					/>
					<span class="d-inline-block">
						{{ mappingSwitchLabel[item.status] }}
					</span>
				</div>
			</template>
		</BaseTable>
	</div>
</template>

<script>
import ManageFilterHeaderInfo from './ManageFilterHeaderInfo.vue';
import ManageFilterSearchAndFilter from './ManageFilterSearchAndFilter.vue';

const FIELDS = [
	{ key: 'name', label: 'Attribute', class: 'col-name' },
	{ key: 'data', label: 'Filter visibility', class: 'col-id text-right w-200' },
];

const mappingSwitchLabel = {
	[true]: 'Show',
	[false]: 'Hide',
};

export default {
	name: 'ManageFilter',
	components: {
		ManageFilterHeaderInfo,
		ManageFilterSearchAndFilter,
	},
	props: {
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		searchValue: {
			type: String,
			default: null,
		},
		filterValue: {
			type: [String, Object],
			default: null,
		},
		filterOptions: {
			type: Array,
			default: () => [],
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		list: {
			type: Array,
			default: () => [],
		},
		tableFields: {
			type: Array,
			default: null,
		},
		tablePagination: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			mappingSwitchLabel,
		};
	},
	computed: {
		localTitle() {
			return this.title || this.$t('components.manageFilter.headerInfo.title');
		},
		localDescription() {
			return this.description || this.$t('components.manageFilter.headerInfo.description');
		},
		fields() {
			return this.tableFields || FIELDS;
		},
	},
	methods: {
		handleEmit(name, value) {
			if (name) {
				this.$emit(name, value);
			}
		},
		handleSearch() {
			this.$emit('onSearch');
		},
		handleSwitch(data) {
			this.$emit('onSwitch', data);
		},
		handlePageChange(page) {
			this.$emit('onPageChange', page);
		},
	},
};
</script>

<style lang="scss" scoped>
	.container-switch {
		margin-top: rem(-4);

		span {
			width: rem(40);
		}

		.switch-custom {
			position: relative;
			top: rem(6);
		}
	}
</style>
