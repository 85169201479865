<template>
	<ManageFilter
		:search-value.sync="queryParams.q"
		:filter-value.sync="dropdown.status"
		:filter-options="ATTRIBUTE_STATUS_OPTIONS"
		:is-loading="isListLoading"
		:list="lists"
		@update:filter-value="handleFilter"
		@onSearch="handleSearch"
		@onSwitch="handleSwitch"
	/>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import ManageFilter from '../components/ManageFilter.vue';
import { cleanObject, randomSearchString, getSelectedValueDropdown, pathOr } from '../assets/js/helpers';
import { transformedManageFilterList } from '../assets/js/transform/marketingCampaigns';
import { ATTRIBUTE_STATUS_OPTIONS, MAPPING_SWITCH_VALUE_TO_STATUS } from '../enums/marketings';

export default {
	name: 'MarketingCampaignAttributeEdit',
	components: {
		ManageFilter,
	},
	data() {
		return {
			ATTRIBUTE_STATUS_OPTIONS,
			queryParams: {
				q: this.$route.query.q || null,
				action: this.$route.query.action || null,
				r: randomSearchString(),
			},
			dropdown: {
				status: getSelectedValueDropdown(this.$route.query.action, ATTRIBUTE_STATUS_OPTIONS),
			},
			marketingCampaignId: this.$route.params.id,
		};
	},
	computed: {
		// Map State
		...mapState('marketingCampaigns', {
			edit: 'edit',
			list: 'attributeKeys',
		}),
		campaignName() {
			return pathOr('', ['data', 'nameEn'])(this.edit);
		},
		// declare lists for table data components
		lists() {
			return transformedManageFilterList(this.list.data);
		},
		isListLoading() {
			return this.list.isLoading || this.edit.isLoading;
		},
	},
	async created() {
		await this.fetchData();

		// add new breadcrumb
		this.addBreadcrumb({
			title: this.campaignName,
			route: {
				name: 'MarketingCampaignEdit',
			},
		});
	},
	methods: {
		...mapActions({
			addBreadcrumb: 'breadcrumb/addBreadcrumb',
			getMarketingCampaign: 'marketingCampaigns/getMarketingCampaign',
			getMarketingCampaignAttributeKeyList: 'marketingCampaigns/getMarketingCampaignAttributeKeyList',
			updateMarketingCampaignAttributeKey: 'marketingCampaigns/updateMarketingCampaignAttributeKey',
		}),
		fetchData() {
			return Promise.all([
				this.getMarketingCampaign(this.marketingCampaignId),
				this.getMarketingCampaignAttributeKeyList({
					id: this.marketingCampaignId,
					params: this.queryParams,
				}),
			]);
		},
		updateUrlParams() {
			const query = cleanObject(JSON.parse(JSON.stringify(this.queryParams)));
			this.$router.push({ query });
		},
		handleSearch() {
			this.updateUrlParams();
		},
		handleSwitch({ id, value }) {
			const params = {
				attribute_key_id: id,
				action: MAPPING_SWITCH_VALUE_TO_STATUS[value],
			};

			// use vuex actions to call rest api, update status
			this.updateMarketingCampaignAttributeKey({
				id: this.marketingCampaignId,
				params,
			});
		},
		handleFilter({ value }) {
			this.queryParams.action = value;

			this.updateUrlParams();
		},
	},
};
</script>
