<template>
	<CRow>
		<CCol md="10">
			<form
				class="search-form"
				@submit.prevent="handleSearch"
			>
				<CInput
					v-model.trim="localSearchValue"
					type="text"
					placeholder="Search"
				>
					<template #prepend-content>
						<button
							type="submit"
							class="btn-search"
						>
							<FontAwesomeIcon :icon="['far', 'search']" />
						</button>
					</template>
				</CInput>
			</form>
		</CCol>
		<CCol md="2">
			<BaseDropDown
				v-model="localFilterValue"
				:options="filterOptions"
				:searchable="false"
				:allow-empty="false"
				class="select-custom"
				label="name"
				track-by="value"
				placeholder="All status"
			/>
		</CCol>
	</CRow>
</template>

<script>
export default {
	name: 'ManageFilterSearchAndFilter',
	props: {
		searchValue: {
			type: String,
			default: '',
		},
		filterValue: {
			type: [String, Object],
			default: null,
		},
		filterOptions: {
			type: Array,
			default: () => [],
		},
	},
	computed: {
		localSearchValue: {
			get() {
				return this.searchValue;
			},
			set(value) {
				this.$emit('update:search-value', value);
			},
		},
		localFilterValue: {
			get() {
				return this.filterValue;
			},
			set(value) {
				this.$emit('update:filter-value', value);
			},
		},
	},
	methods: {
		handleSearch() {
			this.$emit('onSearch');
		},
	},
};
</script>

<style lang="scss" scoped>
	.btn-search {
		background: transparent;
		box-shadow: none;
		border: none;
		padding: 0;
	}
</style>
