var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ManageFilterHeaderInfo',{staticClass:"mb-4",attrs:{"title":_vm.localTitle,"description":_vm.localDescription}}),_c('ManageFilterSearchAndFilter',{attrs:{"search-value":_vm.searchValue,"filter-value":_vm.filterValue,"filter-options":_vm.filterOptions},on:{"update:search-value":function($event){return _vm.handleEmit('update:search-value', $event)},"update:filter-value":function($event){return _vm.handleEmit('update:filter-value', $event)},"onSearch":_vm.handleSearch}}),_c('BaseTable',{staticClass:"table-custom",attrs:{"is-loading":_vm.isLoading,"fields":_vm.fields,"items":_vm.list,"pagination":_vm.tablePagination,"striped":""},on:{"onPaginationClick":_vm.handlePageChange},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"empty-table-element"},[_c('p',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$t("global.searchNotFound", { field: "attribute key" }))+" ")])])]},proxy:true},{key:"name",fn:function(ref){
var item = ref.item; if ( item === void 0 ) item = '';
return [_vm._v(" "+_vm._s(item)+" ")]}},{key:"data",fn:function(ref){
var item = ref.item; if ( item === void 0 ) item = {};
return [_c('div',{staticClass:"container-switch text-right mr-3"},[_c('CSwitch',{staticClass:"switch-custom toggle-status",attrs:{"checked":item.status,"color":"success","variant":"3d","size":"sm"},on:{"update:checked":function($event){return _vm.handleSwitch({
						id: item.id,
						value: $event,
					})}}}),_c('span',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(_vm.mappingSwitchLabel[item.status])+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }