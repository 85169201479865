<template>
	<div class="header-info d-flex">
		<img src="/assets/images/filter-display.svg" alt="filterDisplay" class="mr-4">
		<div class="header-info-description">
			<label
				v-if="title"
				class="typo-body-1"
			>
				{{ title }}
			</label>
			<p
				v-if="description"
				class="typo-body-2 color-black-45"
			>
				{{ description }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ManageFilterHeaderInfo',
	props: {
		title: {
			type: String,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
	},
};
</script>

<style lang="scss" scoped>
	.header-info-description {
		max-width: rem(535);
	}
</style>
